@import "../../utils/stylesheet/mixins.scss";

.container {
  box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.2);
  border-radius: 10px;
  background: #ffffff;
  padding: 1.25rem;
  width: 600px;
  .title {
    @include font-ralewy(26, #2697fe, 500);
    margin-bottom: 0.5rem;
  }
  .text {
    @include font-ralewy(22, #272727, 200, 28);
    margin-bottom: 0.5rem;
    b {
      font-weight: 500;
    }
  }
}
