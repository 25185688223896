@import "../../utils/stylesheet/mixins.scss";

.navContainer {
  @include flex(row, space-between, center);
  width: 100%;
  height: 8vh;
  padding: 0px 60px;
  box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.4);
  background: #ffffff;
  cursor: default;
  position: sticky;
  z-index: 10;
  top: 0px;
  .name {
    @include font-ralewy(32, #2697fe, 600);
  }
  .menu {
    @include flex(row, center, center);
    gap: 2.5rem;
    @include font-ralewy(20, #000000, 300);
    div:hover {
      border-bottom: 4px solid #bfbfbf;
      cursor: pointer;
    }
  }

  .buttons {
    @include flex(row, center, center);
    gap: 1rem;
    .searchBtn {
      @include font-ralewy(18, #ff008a, 300);
      padding: 4px 12px;
      border: 1px solid #ff008a;
      border-radius: 12px;
    }
    .signInBtn {
      @include font-ralewy(18, #ffffff, 300);
      padding: 6px 16px;
      background: #2697fe;
      border-radius: 12px;
    }
    button:active {
      transform: translateY(-1px);
    }
  }
}
