@import "../../utils/stylesheet/mixins.scss";

.container {
  background: #fff;
  box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.4);
  border-radius: 10px;
  padding: 1rem;
  cursor: default;
  width: 400px;
}
.wrapper {
  padding: 0.5rem;
  width: 600px;
  cursor: default;
}
.title {
  @include font-ralewy(22, #2697fe, 500);
  margin-bottom: 0.25rem;
}
.courseContainer {
  @include flex(row, flex-start, center);
  gap: 1.25rem;
  border-bottom: 1px solid #cfcfcf;
  padding: 1.25rem 0rem;
  .image {
    border-radius: 8px;
  }
  .courseName {
    @include font-ralewy(16, #000000, 400);
  }
  .starsNrating {
    @include flex(row, flex-start, center);
    gap: 1.5rem;
    .star {
      @include flex(row, flex-start, center);
      @include font-ralewy(16, #ff008a, 400);
      gap: 0.25rem;
    }
    .rating {
      @include flex(row, flex-start, center);
      @include font-ralewy(16, #727272, 400);
      gap: 0.25rem;
    }
  }
  .like {
    border: 1px solid #000000;
    padding: 0.75rem 0.25rem;
    border-radius: 50%;
    cursor: pointer;
    margin-left: auto;
    margin-right: 1rem;
  }
}
