.aboutCourse {
  width: 100%;
  height: 100%;
}

.mainBar {
  margin-left: 10%;
  padding: 8rem;
  padding-left: 16rem;
}

input[type="text"] {
  padding-left: 1rem;
}

.mainBarHeader {
  display: flex;
  gap: 200px;
  align-items: center;
}

.publishSection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.publishInput {
  width: 60px;
  height: 30px;
  appearance: none;
  background-color: #ccc;
  border-radius: 20px;
  // box-shadow: 1px 1px 1px rgba(38, 151, 24, 0.1);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  transition: all 0.3s;

  display: flex;
  align-items: center;
  cursor: pointer;
}

.publishInput:checked {
  background-color: rgb(38, 151, 254);
}
.publishInput:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background-color: #fff;
  border-radius: 20px;
  transition: all 0.3s;
}

.publishInput:checked:before {
  transform: translateX(30px);
  background-color: #fff;
}

.headerTitle {
  font-size: 24px;
  font-weight: 400;
}
.headerDesc {
  font-size: 16px;
  color: #777;
}

.courseForm {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.courseName > label,
.courseDesc > label {
  color: #777;
}

.courseNameInput,
.courseDescInput {
  padding: 10px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background-color: white;
}

.courseNameInput:focus,
.courseDescInput:focus {
  outline: none;
}

.imgArea {
  background-color: #888;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.noImg {
  color: #333;
  font-size: 22px;
}
.yesImg {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
  overflow: hidden;
  display: block;
}

.fileSection {
  display: flex;
  gap: 20px;
}
.courseImgInput {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.imgLabel {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: #2697fe;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}

.adjustImg {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: #2697fe;
  color: #fff;
  font-weight: bold;
  border: 1px solid #2697fe;
}

.adjustImg:focus {
  background-color: #2697fe;
  color: white;
}

.deleteImg {
  background-color: none;
  border: none;
  color: #2697fe;
}

.courseType > label {
  color: #777;
}

.courseTypeSelect {
  width: 100%;
  padding: 10px;
  height: 50px;
  display: block;
  background-color: white;
  color: #777;
  border: none;
  border-radius: 5px;
}
.courseTypeSelect:focus {
  outline: none;
}

.courseFee > label {
  color: #777;
}
.courseFeeInput {
  padding: 10px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
}

.courseValidity > label {
  color: #777;
  display: block;
}
.courseValidityInput {
  padding: 10px;
  width: 65%;
  height: 50px;
  border-radius: 5px;
}
.courseValiditySelect {
  width: 30%;
  padding: 10px;
  height: 50px;
  background-color: white;
  color: #777;
  border: none;
  float: right;
  border-radius: 5px;
}
.courseValiditySelect:focus {
  outline: none;
}

.startDateLabel,
.endDateLabel {
  color: #777;
  display: block;
}

.commonSelect {
  width: 20%;
  padding: 10px;
  height: 50px;
  color: #777;
  border-radius: 5px;
  border: none;
  background-color: white;
  margin-right: 20px;
}
.commonSelect:focus {
  outline: none;
}

.actionButtons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.courseSave {
  border: none;
  background-color: #2697fe;
  color: white;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 30px;
  border-radius: 5px;
}

.courseBack:link,
.courseBack:visited {
  color: #2697fe;
  display: inline-block;
  font-size: 16px;
}

.courseBack:hover,
.courseBack:focus {
  text-decoration: none;
  color: #2697fe;
}
