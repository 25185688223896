@import "../../utils/stylesheet/mixins.scss";

.liveUpcomingLecUI {
  flex: 1;
  height: 100%;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.2);
  border-radius: 20px;
  padding: 16px 20px;
  // overflow-y: scroll;
}
