@import "../../utils/stylesheet/mixins.scss";

.attendanceBar {
  width: 100%;
  height: 100%;
  display: grid;
  .barHeader {
    @include font-ralewy(14, #2697fe, 300);
    margin-left: 12%;
  }
  .barCenter {
    display: grid;
    grid-template-columns: 1fr 10fr;
    .barDays {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      @include font-ralewy(12, #999, 500);
    }
    .barGraph {
      width: 100%;
      height: 100%;
      border-left: 2px solid #ededed;
      border-bottom: 2px solid #ededed;
      .bars {
        width: 100%;
        height: 100%;
        @include flex(row);
        align-items: flex-end;
        justify-content: flex-start;
        margin-left: 8%;
        gap: 46px;
      }
    }
  }
  .barMonth {
    display: flex;
    @include font-ralewy(12, #999, 500);
    margin-top: 6px;
    margin-left: 8.5%;
  }
}
