@import "../../utils/stylesheet/mixins.scss";

.pastLecture {
  @include flex(row, center, center);
  gap: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
  .img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .textSection {
    margin-top: 10px;
    .name {
      @include font-ralewy(14, rgb(0, 0, 0, 0.8), 330);
    }
    .studentsNum {
      @include font-ralewy(11, #8e9291, 250);
    }
  }
}
