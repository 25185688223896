@import "../../utils/stylesheet/mixins.scss";
.course {
  border-radius: 20px;
  margin-bottom: 30px;
  background-color: white;
  padding: 20px;
  margin-right: 30px;
  @include flex(row, flex-start, flex-start);
  gap: 20px;
  .courseImgSection {
    flex: 1;
    .courseImg {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
  .courseMain {
    flex: 2;
    @include flex(column, flex-start, flex-start);
    padding-right: 140px;
    .courseMainHeader {
      color: #2697fe;
      font-size: 24px;
    }
    .courseMainDesc {
      color: #555;
      line-height: 1.5;
    }
    .courseMainExtra {
      color: #555;
      display: flex;
      gap: 20px;
      .type,
      .duration {
        color: #000;
        margin-left: 5px;
        font-weight: bold;
      }
    }
    .courseMainButtons {
      display: flex;
      gap: 20px;
      .buyCourse {
        border: 1px solid #2697fe;
        border-radius: 5px;
        background-color: white;
        color: #2697fe;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
      .buyCourse:hover {
        color: white;
        background-color: #2697fe;
      }
      .deleteBatch {
        color: #2697fe;
        background-color: white;
        border: none;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
  .courseViewBatch {
    background-color: #fff;
    color: #2697fe;
    height: 10%;
    font-size: 14px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
  }
}
