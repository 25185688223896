.rbc-calendar {
  width: 100%;
}
.rbc-calendar .rbc-time-header {
  display: none;
}
.rbc-calendar .rbc-time-view {
  border: none;
}
.rbc-calendar .rbc-today {
  background: white;
}
.rbc-calendar .rbc-time-content {
  border: none;
  overflow-y: visible;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #dcdcdc;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #dcdcdc;
  min-height: 40px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.rbc-event,
.rbc-event.rbc-selected {
  background-color: #ff3998;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  display: flex;
  outline: none;
}

.rbc-event-label {
  display: none;
}

.rbc-day-slot {
  border: none;
}

.rbc-day-slot .rbc-event {
  border: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
