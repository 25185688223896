@import "../../utils/stylesheet/mixins.scss";

.authButton {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 120px;
  border: none;
  border-radius: 5px;
  background-color: #2697fe;
  color: white;
  border-color: #2697fe;
  &:hover {
    background-color: #fff;
    color: #2697fe;
    cursor: pointer;
    box-shadow: 0px 0px 1px 2px #2697fe;
  }
}
