@import "../../utils/stylesheet/mixins.scss";

.page {
  width: 100%;
  height: 100%;
  cursor: default;
  @include flex(column, center, center);
  .filerBar {
    margin-top: 2rem;
    @include flex(row, center, center);
    gap: 1rem;
    .searchBar {
      height: 70px;
      @include flex(row, space-between, center);
      box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.4);
      border-radius: 10px;
      padding: 0px 1rem;
      gap: 1rem;
      background-color: #fff;
      .form {
        input {
          width: 600px;
          @include font-ralewy(20, #000000, 300);
        }
      }
      .closeIcon {
        color: #777;
      }
      .searchIcon {
        height: 20px;
        padding-left: 8px;
        border-left: 1px solid #9e9797;
        cursor: pointer;
      }
    }
    .filter {
      height: 70px;
      width: 70px;
      @include flex(row, center, center);
      @include font-ralewy(18, #000000, 200);
      box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.4);
      border-radius: 10px;
    }
  }
  .insOrCors {
    margin-top: 2rem;
    @include flex(row, flex-start, center);
    @include font-ralewy(20, #676767, 300);
    width: 65%;
    height: 70px;
    box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.4);
    border-radius: 10px;
    gap: 4rem;
    padding-left: 4rem;
    .active {
      @include flex(row, center, center);
      height: 70px;
      color: #2697fe;
      border-top: 4px solid #2697fe;
    }
    div:hover {
      cursor: pointer;
    }
  }
  .list {
    margin-top: 2.5rem;
    margin-bottom: 5rem;
    @include flex(column, center, center);
    gap: 1.5rem;
  }
  .grid {
    margin-top: 2.5rem;
    margin-bottom: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 3.5rem;
    grid-column-gap: 3.5rem;
  }
}
