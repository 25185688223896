@media screen and (min-width: 1600px){
	.heder_nav {
		margin-top: 20px;
	}
	.navigation_manu {
		width: 10rem !important;
	}
	.content_box {
		margin-left: 10rem;
	}
	.nav_ul_box {
		margin-top: 40px;
	}

	.nav_ul_box li a {
		width: 50px !important;
		height: 50px !important;
		font-size: 1.4rem;
	}

	.logo_box img {
		margin-top: 20px;

		width: 70px !important;
	}

	.nav_ul_box li {
		margin-bottom: 30px;
	}

	.image_profile_box {
		width: 60px;
		height: 60px;
	}

	.profile_box h4 {
		font-size: 19px;
		font-weight: 550;
		padding: 0 20px;
	}

	.ball_icon_box {
		width: 75px !important;
		height: 75px !important;
		font-size: 24px;
	}
	.ball_icon_box span {
		width: 20px;
		height: 20px;
		font-size: 14px;
	}

	.box_vc_nav_right h4 {
		font-size: 30px;
	}

	.btn_box button,
	.box_flex_btn {
		font-size: 25px;
	}

	// .box_flex_btn span {
	// 	font-size: 18px;
	// }

	.teit_all h4 {
		font-size: 23px;
	}
	.teit_all span {
		font-size: 17px;
	}

	.bo_caoe_card {
		padding: 30px 30px;
	}

	.bo_caoe_card i {
		font-size: 24px;
	}

	.bo_caoe_card h4 {
		font-size: 22px;
	}

	.box_rial_card h4,
	.di_fle_box h4 {
		font-size: 24px;
	}

	.box_rial_card span,
	.di_fle_box span {
		font-size: 17px;
	}

	.margin_vs {
		margin-bottom: 60px;
	}

	.all_desbo_conte {
		margin-top: 60px !important;
	}

	.box_dv_r {
		width: 30px;
		height: 30px;
	}

	.box_rial_card {
		padding: 30px 30px;
	}

	.btn_box_moth a {
		font-size: 16px;
		margin-left: 12px;
	}

	.vx_b {
		padding: 4px 15px;
		font-size: 15px;
		font-weight: 300;
	}

	.bbox_ted_f button {
		font-size: 20px;
	}

	.box_alss_ver h4 {
		font-size: 24px !important;
		font-weight: 450;
	}

	.box_alss_ver span {
		font-size: 22px;
	}

	.box_alss_ver2 button {
		padding: 17px 40px;
		font-size: 18px;
		border-radius: 15px;
	}

	.box_alss_ver2 span {
		font-size: 18px;
		padding: 10px 6px 10px;
		//border-radius: 10px;
	}
	.batch_Box {
	}

	.bbox_ted_f {
		//margin-top: 30px;
		//margin-bottom: 10px;
	}

	.class_ver {
		margin-bottom: 20px;
	}

	.btnx_hede {
		height: 70px;
	}

	.btnx_hede span {
		padding-left: 10px;
		font-size: 20px;
	}

	.imgbox_vs {
		width: 80px;
		height: 80px;
	}

	// .text_study2 h5{
	//   font-size: 18px !important;

	// }

	.boxdv {
		width: 60px;
		height: 60px;
	}

	.boxdv i {
		font-size: 20px !important;
	}

	.box_alss_ver h4 {
		font-size: 2px;
		color: #fff;
	}

	.box_alss_ver2 button {
		//padding: 18px 4px !important;
		border-radius: 28px;
	}

	.class_ver {
		padding: 25px;
	}

	.box_vd_s_continer h4 {
		font-size: 18px;
		font-weight: 600;
	}

	.box_btn_liike a {
		padding: 7px 18px;
		font-size: 17px;
	}

	.box_nav_bach button {
		padding: 20px 40px;
		font-size: 20px;
		font-weight: 600;
	}

	.acodition_title h5 {
		font-size: 20px;
	}

	.accodition_content p {
		font-size: 18px;
		margin: 10px 0px 30px 0px;
	}

	.box_vflex_1,
	.box_vflex_2,
	.study_inner {
		padding: 20px !important;
		font-size: 18px !important;
	}
	.box_vflex_2 {
		padding: 20px 100px !important;
	}

	.box_nav_side_bar button {
		font-size: 18px;
		font-weight: 600;
		padding: 15px 15px;
	}

	.search_box {
		width: 450px;
		height: 60px;
		margin: 30px 0px;
	}

	.search_box input {
		font-size: 18px;
		padding-left: 75px;
	}

	.search_box button {
		width: 45px;
		height: 45px;
		font-size: 20px;
	}

	.text_study p {
		font-size: 18px;
	}

	.box_vc_study i {
		font-size: 24px;
	}

	.v_callBatches {
		margin-top: 0px !important;
		padding: 20px 40px;
	}

	.sylabs_text_cont h4 {
		font-size: 20px;
	}

	.sylabs_text_cont p,
	.box_vs_custoSyllbus p {
		font-size: 18px;
	}

	.down_dow_btn_List {
		font-size: 18px;
	}

	.name_tie {
		font-size: 18px !important;
		font-weight: 600 !important;
	}

	.name_subs {
		font-size: 16px !important;
	}

	.accord_Summarey {
		padding: 10px 20px !important;
	}
}
