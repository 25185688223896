@import "../../utils/stylesheet/mixins.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  z-index: 1000;
  background-color: white;
  width: 40%;
  padding-left: 10px;
  .wrapper {
    width: 100%;
    padding: 20px;
    .batchImg {
      width: 100%;
      height: 300px;
      border-radius: 5px;
      margin-bottom: 10px;
      object-fit: cover;
    }
    .imgAction {
      @include flex(row, flex-start, flex-start);
      gap: 10px;
      .addImg {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
      }
      .addImgLabel {
        width: 140px;
        height: 35px;
        border-radius: 5px;
        background-color: #2697fe;
        color: #fff;
        @include flex(row, center, center);
        font-weight: bold;
        cursor: pointer;
        &:hover {
          border: 1px solid #2697fe;
          color: #2697fe;
          background-color: #fff;
        }
      }
      .adjustImg {
        background-color: #2697fe;
        color: white;
        font-weight: 500;
        padding: 5px 10px;
        border-radius: 5px;
      }
      .deleteImg {
        color: #2697fe;
        background-color: none;
        border: none;
      }
    }
    .batch {
      margin-top: 10px;
      @include flex(column, flex-start, flex-start);
      gap: 5px;
      .labelName {
        color: #533;
        font-weight: 400;
      }
      .inputName {
        width: 100%;
        padding: 5px;
        border: 1px solid #2697fe;
      }
    }
    .course {
      margin-top: 10px;
      @include flex(column, flex-start, flex-start);
      gap: 5px;
      .labelCourse {
        color: #533;
        font-weight: 400;
      }
      .courseSelect {
        width: 100%;
        color: #533;
        font-weight: 400;
        padding: 8px;
        background-color: none;
        border: 1px solid #eee;
      }
      .courseOption {
      }
    }
    .date {
      margin: 10px 0px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .labelDate {
        width: 100%;
      }
      .dateT {
        display: flex;
        gap: 15px;
        .daySelect {
          color: #333;
          font-weight: 400;
          padding: 5px;
          background-color: none;
          border: 1px solid #eee;
          border-radius: 5px;
          .dayOption {
          }
        }
        .monthSelect {
          color: #333;
          font-weight: 400;
          padding: 5px;
          background-color: none;
          border: 1px solid #eee;
          border-radius: 5px;
          .monthOption {
          }
        }
        .yearSelect {
          color: #633;
          font-weight: 400;
          padding: 5px;
          background-color: none;
          border: 1px solid #eee;
          border-radius: 5px;
          .yearOption {
          }
        }
      }
    }
    .continueButton {
      margin-top: 15px;
      background-color: #2697fe;
      padding: 10px 80px;
      font-weight: 500;
      color: white;
      border-radius: 5px;
      font-size: 24px;
    }
    .closeButton {
      color: #2697fe;
      background-color: white;
      margin: 20px 10px;
      &:focus {
        outline: none;
      }
      border-bottom: 1px solid #2697fe;
    }
  }
}
