@import "../../utils/stylesheet/mixins.scss";

.invite {
  @include flex(row, flex-start, center);
  gap: 10rem;
  height: 100px;
  border-bottom: 1px solid #f0f0f0;
}

.details {
  @include flex(row, flex-start, center);
  gap: 7rem;
  height: 100px;
  border-bottom: 1px solid #f0f0f0;
}

.first {
  @include flex(row, space-between);
  gap: 8px;
  .img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .content {
    .name {
      @include font-ralewy(16, #585858, 400);
      margin-bottom: 2px;
    }
    .batchId {
      margin-bottom: 0;
      @include font-ralewy(12, #b2b2b2, 400);
    }
  }
}

.phone {
  @include font-ralewy(16, #585858, 300);
  margin-bottom: 0;
}
.email {
  @include font-ralewy(16, #585858, 300);
  margin-bottom: 0;
}
.course {
  @include font-ralewy(16, #767676, 300);
  margin-bottom: 0;
}
.status {
  margin-left: 0.8rem;
  margin-bottom: 0;
}
.status.pending {
  color: #fcb707;
}
.status.accepted {
  color: #2697fe;
}
.status.rejected {
  color: #c00505;
}
.delete {
  margin-left: 1rem;
  background-color: #fff;
  border: 1px solid #2697fe;
  padding: 0.4rem 1.8rem;
  border-radius: 5px;
  @include font-ralewy(14, #6f6f6f, 400);
  &:hover {
    background-color: #2697fe;
    border: 1px solid #fff;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
}
