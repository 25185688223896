.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* ZOOM IN */

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.delay-zoomIn-0\/2 {
  animation: zoomIn 0.2s linear;
}
.delay-zoomIn-0\/3 {
  animation: zoomIn 0.3s linear;
}
.delay-zoomIn-0\/4 {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

/* ZOOM OUT */
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.delay-zoomOut-0\/1 {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.delay-zoomOut-0\/2 {
  animation: zoomOut 0.2s linear;
}
.delay-zoomOut-0\/3 {
  animation: zoomOut 0.3s linear;
}
.delay-zoomOut-0\/4 {
  animation: zoomOut 0.4s linear;
}
.delay-zoomOut-1\/5 {
  animation: zoomOut 1.5s linear;
}
.delay-zoomOut-1 {
  animation: zoomOut 1s linear;
}
.delay-zoomOut-2 {
  animation: zoomOut 2s linear;
}
@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}

/**
        * ----------------------------------------
        * animation scale-up-top
        * ----------------------------------------
        */
.slideUp {
  animation: slideUp 0.5s linear;
}
.delay-up-0\/1 {
  animation: slideUp 0.1s linear;
}
.delay-up-0\/2 {
  animation: slideUp 0.2s linear;
}
.delay-up-0\/3 {
  animation: slideUp 0.3s linear;
}
.delay-up-0\/4 {
  animation: slideUp 0.4s linear;
}
.delay-up-1\/5 {
  animation: slideUp 1.5s linear;
}
.delay-up-1 {
  animation: slideUp 1s linear;
}
.delay-up-2 {
  animation: slideUp 2s linear;
}

@keyframes slideUp {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* SLIDE RIGHT */
.slideRight {
  animation: slideRight 0.5s linear;
}
.delay-right-1 {
  animation: slideRight 1s linear;
}
.delay-right-0\/1 {
  animation: slideRight 0.1s linear;
}
.delay-right-0\/2 {
  animation: slideRight 0.2s linear;
}
.delay-right-0\/3 {
  animation: slideRight 0.3s linear;
}
.delay-right-0\/4 {
  animation: slideRight 0.4s linear;
}
.delay-right-0\/5 {
  animation: slideRight 0.5s linear;
}
.delay-right-1\/5 {
  animation: slideRight 1.5s linear;
}

@keyframes slideRight {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* SLIDE LEFT */
.slideLeft {
  animation: slideLeft 0.5s linear;
}
.delay-left-0\/1 {
  animation: slideLeft 0.1s linear;
}
.delay-left-0\/2 {
  animation: slideLeft 0.2s linear;
}
.delay-left-0\/3 {
  animation: slideLeft 0.3s linear;
}
.delay-left-0\/4 {
  animation: slideLeft 0.4s linear;
}
.delay-left-1\/5 {
  animation: slideLeft 1.5s linear;
}
.delay-left-1 {
  animation: slideLeft 1s linear;
}
.delay-left-2 {
  animation: slideLeft 2s linear;
}
@keyframes slideLeft {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
