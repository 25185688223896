@import "../../utils/stylesheet/mixins.scss";

.detailsPage {
  margin-bottom: 5rem;
  .filerBar {
    margin-top: 2rem;
    @include flex(row, center, center);
    gap: 1rem;
    .searchBar {
      height: 70px;
      @include flex(row, space-between, center);
      box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.4);
      border-radius: 10px;
      padding: 0px 2rem;
      gap: 1rem;
      .form {
        input {
          width: 600px;
          @include font-ralewy(20, #000000, 300);
        }
      }
      .searchIcon {
        height: 20px;
        padding-left: 8px;
        border-left: 1px solid #9e9797;
        cursor: pointer;
      }
    }
    .filter {
      height: 70px;
      width: 70px;
      @include flex(row, center, center);
      @include font-ralewy(18, #000000, 200);
      box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.4);
      border-radius: 10px;
    }
  }

  .detailsWrapper {
    margin-top: 2rem;
    padding: 0rem 15%;
    @include flex(column, flex-start, flex-start);
    gap: 2rem;
    .teachers {
      width: 50%;
      margin: 2rem 0rem;
      .heading {
        @include font-ralewy(26, #2697fe, 500);
        margin-bottom: 0.5rem;
      }
      .teacher {
        padding: 0.765rem 2rem;
        @include flex(row, space-between, center);
        border-bottom: 1px solid #f0f0f0;
        .name {
          @include font-ralewy(22, #000000, 300);
        }
        .subject {
          @include font-ralewy(18, #000000, 200);
        }
      }
    }
  }
  .instituteDetailsWrapper {
    margin-top: 3rem;
    padding: 0rem 15%;
    @include flex(row, space-between, flex-start);
    gap: 3rem;
    .details {
      @include flex(column, flex-start, flex-start);
      gap: 2.5rem;
    }
    .recommendedCard {
      margin-top: 10rem;
    }
  }
}
