@import "../../utils/stylesheet/mixins.scss";

.total {
  flex: 1;
  padding: 3rem 0rem;
  box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.2);
  border-radius: 20px;
  @include font-ralewy(20, #fff, 500);
  @include flex(row, space-around);
}

.totalText {
  font-size: 20px;
  position: relative;
}

.totalText:after {
  content: "";
  width: 6rem;
  height: 1.5px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(15px);
}

.students {
  background-color: #2697fe;
}

.teachers {
  background-color: #ff7cbb;
}

.courses {
  background-color: #38b3c0;
}
