@import "../../utils/stylesheet/mixins.scss";

.teacherStudentInputSection {
  width: 70%;
  height: 40px;
  border: 1px solid #2697fe;
  border-radius: 5px;
  @include flex(row, space-between, center);
  .teacherStudentInput {
    height: 40px;
    background-color: transparent;
    // background-color: red;
    width: 83%;
    height: 100%;
    padding-left: 0.8rem;
  }
  .actions {
    width: 13%;
    padding: 10px 0;
    height: 40px;
    border-left: 1px solid #2697fe;
    border-radius: 5px;
    @include flex(row, space-between);

    .close {
      cursor: pointer;
      width: 34px;
      margin-left: 10px;
      padding-right: 10px;
      border-right: 1px solid #2696fecb;
    }
    .search {
      color: #2697fe;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}
