// @import url("https://fonts.googleapis.com/css2?family=roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  // font-family: "roboto", sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

button:focus,
input:focus {
  outline: none !important;
}

@mixin flex_box_k($pro) {
  display: flex;
  justify-content: $pro;
  align-items: center;
}

$bg-color: #fbfbfb;
$primary-color: #2697fe;
$pri-gray-color: #eeeeee;

.box_ladto {
  display: flex;
}

.textCenter {
  text-align: center;
}

.content_full_container {
  background: $bg-color;
  width: 100%;
  overflow-y: hidden;
  max-height: 100%;
  min-height: 100vh;
}

.navigation_manu {
  width: 7rem;
  max-height: 100%;
  min-height: 100vh;
  background: #ffffff;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  overflow-y: scroll;
  box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.4);

  /* box-shadow: 0px 0px 30px #000; */
}

.navigation_manu::-webkit-scrollbar {
  width: 0px;
}

.content_box {
  width: 100%;
  margin-left: 7rem;
  max-height: 100%;
  min-height: 100vh;
  background: #fbfbfb;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo_box img {
  width: 55px;
  height: auto;
}

.logo_box {
  width: 100%;
  display: flex;
  height: 90px;
  justify-content: center;
  align-items: center;
}

.nav_ul_box {
  width: 100%;
  margin-top: 10px;
  padding: 0;
}

.nav_ul_box li {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-bottom: 30px;
}

.nav_ul_box li a {
  @include flex_box_k(center);
  width: 34px;
  height: 34px;
  text-decoration: none;
  font-size: 1rem;
  background: transparent;
  color: #cacaca;
  border-radius: 6px;
  transition: 0.5s;
}

.nav_ul_box li a:hover {
  background: $primary-color;
  color: #fff;
}

.nav_ul_box li .active {
  background: $primary-color;
  color: #fff;
}

// .svg-color{
//   // background: $primary-color;
//   color: white;
// }

.heder_nav {
  @include flex_box_k(space-between);
  height: 100px;
  width: 100%;
  background: transparent;
  padding: 1rem 40px 30px;
  // position: fixed;
  // max-width: calc(100rem/0.5)
}

.profile_box,
.box_vc_nav_left {
  // display: flex;
  // align-items: center;
  @include flex_box_k(normal);
}

.profile_box {
  padding: 8px 15px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.048);
  border-radius: 20px;
}

.image_profile_box {
  width: 45px;
  height: 45px;
  border-radius: 37%;
  overflow: hidden;
}

.image_profile_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile_box h4 {
  font-size: 20px;
  font-weight: 500;
  padding: 0 15px;
  z-index: 11;
  margin-bottom: 0;
}

.btnx_hede {
  @include flex_box_k(normal);
  height: 60px;
  width: auto;
  padding: 0px 40px;
  background: #ff3998;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.btnx_hede span {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 550;
}

.box_vc_nav_right a {
  @include flex_box_k(center);
  width: auto;
  cursor: pointer;
  //height: 60px;
  padding: 25px 40px;
  background: $pri-gray-color;
  font-size: 18px;
  text-decoration: none;
  color: #333;
  margin-right: 20px;
  border-radius: 20px;
}

.box_vc_nav_right22 {
  @include flex_box_k(normal);
}

.box_vc_nav_right span {
  font-size: 14px;
  color: #444;
  font-weight: 400;
}

.ball_icon_box {
  @include flex_box_k(center);
  width: 60px;
  cursor: pointer;
  height: 60px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.048);
  border-radius: 20px;
  font-size: 18px;
  color: #333;
  margin: 0px 30px;
  position: relative;
}

.ball_icon_box span {
  @include flex_box_k(center);
  width: 15px;
  height: 15px;
  font-size: 11px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  background: #fdbb14;
  color: #fff;
}

.box_vc_nav_right h4 {
  font-size: 15px;
  font-weight: 700;
  color: #333;
  margin-bottom: 0px;
  margin-right: 10px;
  padding-right: 10px;
  text-transform: capitalize;
}

.box_vc_nav_right22 h4 {
  border-right: 1px solid #e0e0e0;
}

.content_box {
  position: relative;
}

.content_box::before {
  position: absolute;
  top: -30px;
  left: 10px;
  content: "";
  background: url(../image/SVG/bulb_front.svg);
  width: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.4;
  height: 350px;
}

.content_box::after {
  position: absolute;
  bottom: 10px;
  right: 10px;
  content: "";
  background: url(../image/SVG/calendar_front.svg);
  width: 350px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 350px;
}

.content_box22::before {
  display: none;
}

.content_box22::after {
  display: none;
}

.btn_box {
  display: flex;
  position: relative;
}

.box_flex_btn_grey {
  background: $pri-gray-color;
}

.btn_box button,
.box_flex_btn {
  display: inline-block;
  border-radius: 20px;
  border: none;
  // padding: 12px 0;
  color: #8f8f8f;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  margin-right: 30px;
}

.box_flex_btn_primary {
  background: $primary-color;
  color: #ffffff;
}

.btn_box button:focus {
  outline: none;
}

.conte_box_deboard {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
}

.box_flex_btn {
  padding: 12px 20px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.box_flex_btn span {
  padding: 0.2rem 1.5rem;
  background: #f8f8f8;
  color: #8f8f8f;
  font-size: 20px;
  border-radius: 49px;
  margin-left: 20px;
}

.teit_all {
  position: relative;
}

.teit_all h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0px !important;
  color: #333;
}

.teit_all span {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
  color: #8f8f8f;
}

.all_desbo_conte {
  margin-top: 40px;
}

.box_cord_gird {
  display: flex;
  margin-top: 12px;
}

.bo_caoe_card {
  padding: 25px 30px;
  background: $pri-gray-color;
  @include flex_box_k(normal);
  border-radius: 20px;
  margin-right: 40px;
}

.bo_caoe_card i {
  font-size: 20px;
  color: #444;
  margin-right: 15px;
}

.bo_caoe_card h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #444;
  text-transform: capitalize;
}

.bo_caoe_card h4 span {
  color: #444;
}

.box_rial_card {
  position: relative;
  width: 100%;
  padding: 20px 20px;
  background: $pri-gray-color;
  border-radius: 20px;
}

.box_rial_card h4,
.di_fle_box h4 {
  font-size: 20px;
  font-weight: 700;
  color: #333;
}

.box_rial_card span,
.di_fle_box span {
  font-size: 13px;
  color: rgb(128, 128, 128);
  text-transform: capitalize;
}

.margin_vs {
  margin-bottom: 40px;
}

.box_vdtd {
  margin-top: 10px;
}

.box_r_activ {
  background: $primary-color;
}

.box_r_activ h4 {
  color: #fff;
}

.box_r_activ span {
  color: #fff;
}

.box_contec_3 {
  position: relative;
  z-index: 777;
}

.box_dv_r {
  @include flex_box_k(center);
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fc3b68;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  top: 15px;
  right: 15px;
  box-shadow: 1px 1px 5px #fc3b68, 2px 2px 5px #fc3b68, 3px 3px 5px #fc3b68,
    4px 4px 20px #fc3b68;
}

.btn_box_moth a {
  display: inline-block;
  padding: 8px 20px;
  font-weight: 400;
  text-decoration: none;
  font-size: 13px;
  text-transform: capitalize;
  border-radius: 50px;
  margin-left: 6px;
  background: rgba(34, 34, 34, 0.315);
  color: #fff;
  transition: all 0.5s;
}

.btn_box_moth a:hover {
  background: #3f3f3f;
}

.btn_box_moth .active {
  background: #3f3f3f;
}

.teit_all_flex {
  @include flex_box_k(space-between);
  margin-bottom: 30px;
}

.box_vdtd_trac_box {
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(34, 34, 34, 0.103);
  border-radius: 20px;
  padding: 20px 25px;
  @include flex_box_k(space-between);
  align-items: normal;
}

.border_vs {
  border-right: 2px solid rgb(224, 224, 224);
}

.di_fle_box {
  position: relative;
}

.vx_b {
  position: absolute;
  top: 0px;
  left: 50px;
  background: #ccc;
  color: #fff;
  border-radius: 40px;
  padding: 4px 15px;
  font-size: 12px;
  font-weight: 300;
}

.colo_1 {
  background: #46b586;
}

.di_fle_box h4 {
  position: relative;
}

.colo_2 {
  background: #ff1148;
}

.box_vc_nav_le_2 {
  display: none;
}

.bbox_ted_f {
  @include flex_box_k(space-around);
  align-items: normal;
  border-radius: 40px;
  background: rgba(238, 238, 238, 0.5);
  margin-top: 20px;
  margin-bottom: 30px;
  position: sticky;
  z-index: 1;
}

.bbox_ted_f button {
  padding: 30px 20px;
  color: #222;
  text-decoration: none;
  border: none;
  font-size: 20px;
  font-weight: 550;
  background: none;
  display: inline-block;
}

.active_v {
  position: relative;
  color: $primary-color !important;
}

.bbox_ted_f .active_v::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  display: inline-block;
  height: 8px;
  background: $primary-color;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.class_ver {
  @include flex_box_k(space-between);
  width: 100%;
  background: $primary-color;
  border-radius: 30px;
  padding: 20px 40px;
  margin-bottom: 20px;
}

.box_alss_ver,
.box_alss_ver2 {
  display: flex;
}

.box_alss_ver2xd button {
  padding: 12px 30px !important;
}

.box_alss_ver h4 {
  font-size: 20px !important;
  color: #fff;
  //width: 7rem !important;
  margin: 0px !important;
  //margin-right: 30px !important;
  font-weight: 450;
}

.box_alss_ver span {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}

.box_alss_ver span i {
  margin-right: 6px;
}

.box_alss_ver2 button {
  background: #fff;
  color: #222;
  border-radius: 28px;
  border: none;
}

.box_alss_ver2 span,
a {
  font-size: 13px;
  color: #222;
  font-weight: 400;
  display: inline-block;
  //background:  #FFD981;
  border-radius: 4px;
  padding: 4px 10px;
  line-height: 22px;
  //margin-right: 40px;
}

.box_alss_ver2 a {
  padding: 0px;
  margin-right: 40px;
}

.class_over_view_con {
  position: relative;
  z-index: 333;
}

.bg_tex_1 {
  background: #fcd6e4 !important;
}

.bg_tex_2 {
  background: #dabaff !important;
}

.box_vc_nav_le_2 {
  display: none;
}

.vs_rs_tbox {
  display: none;
}

.active_box {
  display: block !important;
}

.box_flex_ {
  background: $primary-color !important;
  color: #fff !important;
  font-size: 16px !important;
}

.profile_box {
  position: relative;
  transition: 0.5s;
}

.profiel_derowpdowN {
  position: absolute;
  top: 100px;
  right: 0;
  padding: 4px 8px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.082);
  width: 220px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  z-index: -2323;
  transition: all 0.5s;
}

.profile_box:hover .profiel_derowpdowN {
  top: 70px;
  opacity: 1;
  visibility: visible;
  z-index: 2323;
}

.profiel_derowpdowN a {
  width: 100%;
  padding: 14px 20px;
  color: #333;
  text-decoration: none;
  background: $pri-gray-color;
  border-radius: 20px;
  transition: all 0.5s;
  font-weight: 700;
}

.profiel_derowpdowN a i {
  margin-right: 30px;
}

.profiel_derowpdowN .activ_hover {
  background: #fcd6e4;
  color: #ff1148;
}

.profiel_derowpdowN a:hover {
  opacity: 0.8;
}

.box_vd_s_continer {
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.082);
  border-radius: 20px;
  z-index: 1111;
  position: relative;
  margin-top: 15px;
  margin-bottom: 30px;
}

.box_vd_s_continer h4 {
  font-size: 15px;
  font-weight: 700;
  color: #333;
}

.box_btn_liike {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
}

.box_btn_liike a {
  padding: 7px 18px;
  font-size: 13px;
  background: #ccc;
  border-radius: 40px;
  margin-right: 15px;
  margin-top: 15px;
  text-decoration: none;
  color: #333;
}
a.color2_ba:hover {
  cursor: pointer;
}

.box_vd_contsiner {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

// batches

.box_nav_bach {
  @include flex_box_k(space-between);
  width: 100%;
  padding: 20px 70px;
  background: #eeeeee;
  border-radius: 40px;
}

.box_nav_bach button {
  padding: 14px 40px;
  background: transparent;
  font-size: 15px;
  font-weight: 500;
  color: #666;
  border-radius: 20px;
  border: none;
}

.box_nav_bach .active_btns,
.box_nav_bach button:hover {
  background: #d8d8d8;
  color: #444;
}

.accoditon_vs {
  margin-bottom: 20px !important;
  border-radius: 25px !important;
  font-size: 15px !important;
  background: #eeeeee !important;
  border: none !important;
}

.accoditon_vs_2 {
  background: #2697fe !important;
}

.acodition_title2 {
  @include flex_box_k(normal);
  width: 100%;
  flex-direction: column;
  align-items: stretch !important;
}

.acodition_title {
  @include flex_box_k(space-between);
  width: 100%;
}

.acodition_title h5 {
  font-size: 14px;
  margin-bottom: 0px;
}

.acodition_title span {
  font-size: 18px !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.icon_box_ac span {
  font-weight: 400;
  margin: 0px;
  margin-right: 20px;
  color: #333 !important;
}

.icon_box_ac span i {
  margin-right: 8px !important;
}

.accodition_content p {
  font-size: 15px;
  color: #333;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 25px;
}

.box_vflex_1,
.box_vflex_2,
.study_inner {
  background: #2697fe !important;
  padding: 8px 40px !important;
  font-size: 15px !important;
  color: #fff !important;
  border-radius: 25px !important;
  margin-right: 0px !important;
  margin-left: 20px;
}

.box_vflex_2 {
  background: #ff3998 !important;
  padding: 8px 60px !important;
}

.btn_box2 {
  @include flex_box_k(end);
}

.d_nones {
  display: none;
}

.d_blocks {
  display: block;
}

// Search Style

.search_box {
  width: 330px;
  height: 50px;
  position: relative;
  margin: 20px 0px;
}

.search_box input {
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.027);
  border-radius: 10px;
  padding-left: 60px;
  font-size: 14px;
  font-weight: 400;
}

.search_box button {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background: #eeeeee;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

// Search Style End

.study_inner {
  padding: 10px 20px !important;
  @include flex_box_k(normal);
  margin: 0px !important;
  margin-bottom: 20px !important;
}

.box_vc_study {
  @include flex_box_k(center);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #7dc1fe;
  margin-right: 20px;
}

.box_vc_study i {
  font-size: 18px;
}

.text_study p {
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
}

.box_nav_side_bar {
  width: 100%;
  border-radius: 30px;
  padding: 20px 20px;
  background: #eeeeee;
  overflow-y: "auto";
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.box_nav_side_bar button {
  display: block;
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 10px;
  color: #5e5e5e;
  width: 100%;
  text-align: start;
  border-radius: 20px;
  margin-bottom: 8px;
  transition: 0.3s;
}

.box_nav_side_bar .active_btns,
.box_nav_side_bar button:hover {
  background: #2697fe;
  color: #fff;
}

.sylabs_text_cont {
  padding: 30px;
  //margin-bottom: 20px;
}

.sylabs_text_cont h4 {
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin-bottom: 15px;
}

.sylabs_text_cont p,
.box_vs_custoSyllbus p {
  font-size: 14px;
  color: #444;
  font-weight: 400;
}

.accoditon_vs2 {
  background: transparent !important;
}

.conbox_accodition2 {
  background: #2697fe;
  margin-top: 15px;
}

.accodition_content2 p {
  color: #fff;
}

.box_vs_custoSyllbus {
  max-width: 400px;
  min-width: 200px;
  padding: 20px 20px;
}

.btn_box_self button {
  padding: 6px 15px;
  font-size: 14px;
  border-radius: 10px;
  margin-right: 15px;
  color: #ff3998;
  background: #ffb0d6;
}

.down_dow_btn_List {
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 10px;
  background: #7dc1fe;
  color: #fff;
  border: none;
  font-weight: 500;
  margin-bottom: 15px;
  margin-right: 15px;
}

.down_dow_btn_List i {
  margin-left: 15px;
}

.prover_syllus_vs {
  border-radius: 50px !important;
}

.imgbox_vs {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.imgbox_vs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text_study2 h5 {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0px;
}

.study_inner2 {
  width: 100%;
  position: relative;
  @include flex_box_k(normal);
}

.study_inner2 h4 {
  margin: 0;
  font-size: 25px;
  font-weight: 550;
}

.dispvx {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  font-size: 20px;
}

.title_all_box h4 {
  font-weight: 700;
  font-size: 17px;
  color: #333;
  margin-top: 30px;
  margin-bottom: 20px;
}

.activ {
  margin-top: 4px;
  display: none;
}

.activ_vx {
  display: block;
}

.activ p {
  font-weight: 400;
  font-size: 12px;
  color: rgb(243, 243, 243);
}

.marvd {
  margin-left: 5% !important;
  margin-right: 10% !important;
}

.chebox_custom,
.chebox_custom2 {
  border: 2px solid #fff !important;
  border-radius: 15px !important;
  color: #2697fe !important;
}

.chebox_custom2 {
  background: #fff !important;
}

.chebox_custom input {
  border: none !important;
}

.vsbd {
  @include flex_box_k(normal);
}

.box_st_positio {
  @include flex_box_k(center);
  width: 100%;

  height: auto;
  padding: 40px 0px 70px 0px;
  background: rgba(255, 255, 255, 0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99933333;
  backdrop-filter: blur(20px);
}

// // form

.box_form_custom {
  width: 450px;
  padding: 30px 40px;
}

.box_vde {
  width: 100%;
  height: 100%;
  margin-bottom: 15px !important;
  color: #222 !important;
  padding-right: 20px;
  font-size: 14px !important;
}

.MuiFilledInput-root {
  border-radius: 20px !important;
  background: #eeeeee;
}

.MuiFilledInput-root input {
  width: 100%;
  color: #222 !important;
  font-size: 14px !important;
  padding-left: 22px;
  font-size: 15px;
  background: transparent;
  color: #444;
  font-weight: 500;
}

// .formboxv{
//   width: 100%;
//   height: 50px;
//   border-radius: 20px;
//   background: #eeeeee;
//   position: relative;
// }

.box_vde input {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  padding: 32px 20px 10px 22px;
  font-size: 15px;
  color: #444;
  font-weight: 500;
}

// .formboxv span{
//   position: absolute;
//   top: 20x;
//   left: 15px;
//   font-size: 15px;
//   font-weight: 500px;
//   display: inline-block;
//   padding: 14px 8px;
//   color: rgb(109, 109, 109);
//   transition: 0.3s;
//   pointer-events: none;

// }

// .formboxv input:focus ~ label,
//   .formboxv input:valid ~ label
//   {
//       transform: translateX(0px) translateY(-15px);
//       font-size: 12px;
//       background: rgb(255, 255, 255);
//       border-radius: 4px;
//       padding: 4px 8px;
//       color: #4530ae;
//   }

.box_vde {
  border-radius: 15px !important;
  background: #eeeeee;
}

.box_vde .MuiFilledInput-underline:after,
.box_vde .MuiFilledInput-underline:before {
  display: none !important;
}

.box_vde .MuiFilledInput-root,
.box_vde .MuiFilledInput-root:hover {
  background: transparent !important;
}

.box_vde {
  font-size: 1px !important;
}

.btn_vs_over_2v {
  background: #46b586 !important;
  color: #fff !important;
  width: 80%;
  margin: 0 auto;
  padding: 12px 20px;
}

// .vsxd{
//   z-index: 4444444 !important;
// }
.vsxd .MuiPaper-root {
  border-radius: 40px !important;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
  color: #777 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #777 !important;
}

// remove end
.color1_ba {
  background: #90d0f0 !important;
}

.color2_ba {
  background: #dabaff !important;
}

.color3_ba {
  background: #fcd6e4 !important;
}

.color4_ba {
  background: #b1f1cc !important;
}

.color5_ba {
  background: #ffd981 !important;
}

.boxdv {
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background: #a8d5ff;
  border: none;
  color: #fff;

  margin-right: 20px;
}

.boxdv:hover {
  background: #9acdfc;
}

.vsd_show {
  margin-left: auto !important;
}

.vsd_show span {
  margin: 0px 5px;
}

.modeL_style {
  text-align: center;
}

.modeL_style h6 {
  font-size: 24px;
  font-weight: 500;
}

.modeL_style span {
  display: block;
  color: #a3a3a3;
  font-size: 13px;
}

.btn_box_vdc {
  @include flex_box_k(center);
  margin-top: 40px;
}

.btn_box_vdc button {
  margin: 0px;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 550;
}

.clorv {
  background: #ff1148 !important;
  color: #fff !important;
  margin-left: 20px !important;
  border: 2px solid #ff1148 !important;
}

.clorv2 {
  background: transparent !important;
  border: 2px solid rgb(99, 99, 99) !important;
  color: #444 !important;
}

.clorv:hover,
.clorv2:hover {
  opacity: 0.8;
}

.btnx_hede2dc {
  width: 100% !important;
}

.btnx_hede2dc .btnx_hede {
  margin: 0px !important;
  display: flex;
  background: #ff3998 !important;
  border-radius: 20px;
  @include flex_box_k(center);
  height: 55px !important;
  font-size: 15px;
  font-weight: 700;
}

.btn_custond {
  width: 100%;
  height: 55px;
  background: #2697fe;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 20px;
}

.mavde {
  margin-top: 60px !important;
  justify-content: center;
}

.obx_co {
  @include flex_box_k(center);
}

.obx_co button {
  width: 20px;
  height: 20px;
  @include flex_box_k(center);
  background: #ff1148;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.bxd {
  width: 40%;
  height: auto;
  overflow: hidden;
}

.btn_vs_overNot {
  padding: 8px 20px !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  background: #7dc1fe !important;
  color: #fff !important;
  margin-right: 10px !important;
}

.bxd2 {
  transition: width 0.3s;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  overflow: hidden;
}

.pLink {
  text-decoration: none !important;
}

.profile_cont {
  width: 100%;
  height: 300px;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
}

.profile_cont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.uploadbtn {
  position: absolute;
  bottom: 15px;
  right: 60px;
}

.btnx_hedex {
  padding: 8px 25px !important;
  background: #fff !important;
  border-radius: 15px !important;
}

.icon_uplo {
  width: 30px !important;
  color: #222 !important;
}

.profile_img_con {
  width: 220px;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pro_inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.pro_inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.uploadbtn2 {
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -110px;
}

.btnx_hede2dc33 {
  margin-bottom: 0px !important;
  padding: 15px 25px !important;
}

.textAreaca {
  width: 100%;
  border-radius: 20px;
  background: #eee;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  color: #444;
  border: none;
  padding: 10px 20px;
}

.consi img {
  width: 100px;
}

.consi {
  @include flex_box_k(start);
}

.consi h4 {
  font-size: 15px;
  font-weight: 500;
  color: #333;

  margin-bottom: 0px;
}

.box_del {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(34, 34, 34, 0.219);
  border-radius: 20px;
  padding: 25px 30px;
}

.hover_box {
  position: relative;
}

.dorndow_box {
  position: absolute;
  top: 100px;
  right: -20px;
  width: 280px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  padding: 10px 15px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.082);
  transition: 0.5s;
}

.hover_box:hover .dorndow_box {
  top: 50px;
  z-index: 234234;
  opacity: 1;
  visibility: visible;
}

.dorndow_box h4 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(204, 204, 204);
  margin-bottom: 8px !important;
}

.dorndow_box span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  word-wrap: break-word;
}

.input_f_box {
  width: 100%;
  height: 55px;
  background: #eeeeee;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

.input_f_box h4 {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0px;
}

.v_callBatches {
  margin: 0;
  padding: 0;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.itemall {
  overflow: hidden !important;
  height: 450px;
}

.studyMari_d {
  height: 50%;
  overflow-y: auto;
}

.font-wg-500 {
  font-weight: 500 !important;
}
.font-wg-550 {
  font-weight: 550 !important;
}

.student_details h4 {
  margin: 0;
  font-size: 24px;
  font-weight: 550;
}
