@import "../../utils/stylesheet/mixins.scss";

.content {
  width: 100%;
  height: 92vh;
  @include flex-center(column);

  .name {
    @include font-ralewy(46, #000000, 600);
    margin-top: 1rem;
  }
  .searchBar {
    height: 70px;
    @include flex(row, space-between, center);
    box-shadow: 0px 4px 40px rgba(211, 211, 211, 0.2);
    border-radius: 30px;
    padding: 0px 1rem;
    padding-left: 0.6rem;
    gap: 1rem;
    background-color: #fff;
    .form {
      input {
        width: 500px;
        @include font-ralewy(20, #000000, 300);
      }
    }
    margin-top: 1.25rem;
  }
  .searchText {
    @include font-ralewy(20, #4b4a4a, 300);
    margin-top: 2.5rem;
  }
  .footer {
    @include flex-center(row);
    @include font-ralewy(18, #000000, 200);
    gap: 1.5rem;
    margin-top: 7rem;
  }
}
